import { createSlice, Slice } from '@reduxjs/toolkit';
import { ProofingReducerTypes } from './types/proofingReducer.types';

const initialState: ProofingReducerTypes = {
  artwork_versions: [],

  primary_version: null,
  primary_version_add_comment: false,
  primary_version_id: null,
  primary_page_id: null,
  primary_page_index: 0,
  primary_page_length: null,
  primary_image: null,
  primary_dots: [],
  primary_comments: [],

  compare: false,
  comparable_version: null,
  comparable_available_versions: null,
  comparable_version_add_comment: false,
  comparable_version_id: null,
  comparable_page_id: null,
  comparable_page_index: 0,
  comparable_page_length: null,
  comparable_image: null,
  comparable_dots: [],
  comparable_comments: [],

  add_comment_axis: null,
  add_comment_dialog: false,
};

export const proofingSlice: Slice<ProofingReducerTypes> = createSlice({
  name: 'proofing',
  initialState,
  reducers: {
    set_artwork_versions: (state, action) => {
      state.artwork_versions = action.payload;
    },
    set_primary_version_id: (state, action) => {
      state.primary_version_id = action.payload;
    },
    set_primary_version: (state, action) => {
      state.primary_version = action.payload;
    },
    set_primary_version_add_comment: (state, action) => {
      state.primary_version_add_comment = action.payload;
    },
    set_primary_page_id: (state, action) => {
      state.primary_page_id = action.payload;
    },
    set_primary_page_index: (state, action) => {
      state.primary_page_index = action.payload;
    },
    set_primary_image: (state, action) => {
      state.primary_image = action.payload;
    },
    set_primary_page_length: (state, action) => {
      state.primary_page_length = action.payload;
    },
    set_primary_dots: (state, action) => {
      state.primary_dots = action.payload;
    },
    set_primary_comments: (state, action) => {
      state.primary_comments = action.payload;
    },
    set_compare: (state, action) => {
      state.compare = action.payload;
    },
    set_comparable_version: (state, action) => {
      state.comparable_version = action.payload;
    },
    set_comparable_available_versions: (state, action) => {
      state.comparable_available_versions = action.payload;
    },
    set_comparable_version_add_comment: (state, action) => {
      state.comparable_version_add_comment = action.payload;
    },
    set_comparable_version_id: (state, action) => {
      state.comparable_version_id = action.payload;
    },
    set_comparable_page_id: (state, action) => {
      state.comparable_page_id = action.payload;
    },
    set_comparable_page_index: (state, action) => {
      state.comparable_page_index = action.payload;
    },
    set_comparable_page_length: (state, action) => {
      state.comparable_page_length = action.payload;
    },
    set_comparable_image: (state, action) => {
      state.comparable_image = action.payload;
    },
    set_comparable_dots: (state, action) => {
      state.comparable_dots = action.payload;
    },
    set_comparable_comments: (state, action) => {
      state.comparable_comments = action.payload;
    },
    set_add_comment_axis: (state, action) => {
      state.add_comment_axis = action.payload;
    },
    set_add_comment_dialog: (state, action) => {
      state.add_comment_dialog = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  set_artwork_versions,
  set_primary_version_id,
  set_primary_version,
  set_primary_version_add_comment,
  set_primary_page_id,
  set_primary_page_index,
  set_primary_image,
  set_primary_page_length,
  set_primary_dots,
  set_primary_comments,
  set_compare,
  set_comparable_version,
  set_comparable_available_versions,
  set_comparable_version_add_comment,
  set_comparable_version_id,
  set_comparable_page_id,
  set_comparable_page_index,
  set_comparable_page_length,
  set_comparable_image,
  set_comparable_dots,
  set_comparable_comments,
  set_add_comment_axis,
  set_add_comment_dialog,
} = proofingSlice.actions;

export default proofingSlice.reducer;
