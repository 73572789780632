import React, { Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Loader: React.FC = (): React.ReactElement => (
  <CircularProgress
    color="secondary"
    sx={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}
  />
);

const SuspenseContainer: React.FC<{ children: React.ReactElement }> = ({
  children,
}): React.ReactElement => {
  return <Suspense fallback={<Loader />}>{children}</Suspense>;
};

export default SuspenseContainer;
