import { createSlice, Slice } from '@reduxjs/toolkit';
import { ProjectReducerTypes } from './types/projectReducer.types';

const initialState: ProjectReducerTypes = {
  project_data: null,
  project_data_internal: null,
  project_data_client: null,
  internal_artwork_versions: [],
  internal_reviewers: [],
  internal_comments: [],
  client_artwork_versions: [],
  client_reviewers: [],
  client_comments: [],
  final_assets: [],
  active_version: null,
  internal_decision_mode: false,
  client_decision_mode: false,
};

export const projectSlice: Slice<ProjectReducerTypes> = createSlice({
  name: 'project',
  initialState,
  reducers: {
    set_project_data: (state, action) => {
      state.project_data = action.payload;
    },
    set_project_data_internal: (state, action) => {
      state.project_data_internal = action.payload;
    },
    set_project_data_client: (state, action) => {
      state.project_data_client = action.payload;
    },
    set_internal_artwork_versions: (state, action) => {
      state.internal_artwork_versions = action.payload;
    },
    set_internal_reviewers: (state, action) => {
      state.internal_reviewers = action.payload;
    },
    set_internal_comments: (state, action) => {
      state.internal_comments = action.payload;
    },
    set_client_artwork_versions: (state, action) => {
      state.client_artwork_versions = action.payload;
    },
    set_client_reviewers: (state, action) => {
      state.client_reviewers = action.payload;
    },
    set_client_comments: (state, action) => {
      state.client_comments = action.payload;
    },
    set_final_assets: (state, action) => {
      state.final_assets = action.payload;
    },
    set_active_version: (state, action) => {
      state.active_version = action.payload;
    },
    set_internal_decision_mode: (state, action) => {
      state.internal_decision_mode = action.payload;
    },
    set_client_decision_mode: (state, action) => {
      state.client_decision_mode = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  set_project_data,
  set_project_data_internal,
  set_project_data_client,
  set_internal_artwork_versions,
  set_internal_reviewers,
  set_internal_comments,
  set_client_artwork_versions,
  set_client_reviewers,
  set_client_comments,
  set_final_assets,
  set_active_version,
  set_internal_decision_mode,
  set_client_decision_mode,
} = projectSlice.actions;

export default projectSlice.reducer;
