import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { set_user, set_user_reset_password } from '../Store/reducers/userReducer';
import { RootState } from '../Store/Store';
import api from '../services/api/axios-interceptor';
import { setErrorToastState } from '../Store/action';
import ForceResetPassword from '../UserAuthentication/Components/ForceResetPassword/ForceResetPassword';

type ProtectedRouteType = {
  children: React.ReactElement;
};

const ProtectedRoute: React.FC<ProtectedRouteType> = ({ children }: ProtectedRouteType) => {
  const [loading, setLoading] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  const dispatch = useDispatch();
  const user = useSelector(({ user }: RootState) => user.user);
  const route = useNavigate();

  useEffect(() => {
    if (user?.id) {
      api
        .get(`/users/${user.id}`)
        .then(res => {
          dispatch(set_user_reset_password(res.data.data.password_reset === 1 ? true : false));
          dispatch(set_user(res.data.data));
          localStorage.setItem('user', JSON.stringify(res.data.data));
          setLoading(false);
        })
        .catch(err => {
          setServerErrorMessage(err?.response?.data?.message);
          setLoading(false);
        });
    }
  }, [dispatch, route]);

  if (!user?.id) {
    return <Navigate to="/" />;
  }

  return (
    <>
      {children}
      <ForceResetPassword />
    </>
  );
};

export default ProtectedRoute;
