import React, { useState } from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/Store';
import { Formik, Form, Field, ErrorMessage, FieldProps } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Watch } from 'react-loader-spinner';
import { ForceResetPasswordTypes } from '../NewPassword/types/NewPassword.types';
import { setNewPassword, fetchUser } from '../../../services/api/userApi';
import { set_user, set_user_reset_password } from '../../../Store/reducers/userReducer';
import { useDispatch } from 'react-redux';

export const ForceResetPassword: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch();

  const user_reset = useSelector(({ user }: RootState) => user.user_reset_password);
  const userId = useSelector(({ user }: RootState) => user?.user?.id);
  const [passwordVisibility, setPasswordVisibility] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [loading, setLoading] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  const ResetPassword = Yup.object().shape({
    current_password: Yup.string().required('Required!'),
    password: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password must contain at least 8 characters, one uppercase letter, one number, and one special character',
      )
      .required('Required!')
      .notOneOf(
        [Yup.ref('current_password')],
        'New password must be different from current password',
      ),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), undefined], "Passwords don't match")
      .required('Required!'),
  });

  const initialValues = {
    current_password: '',
    password: '',
    password_confirmation: '',
  };

  const handleResetPassword = (data: ForceResetPasswordTypes) => {
    setLoading(true);
    setServerErrorMessage('');
    if (userId) {
      setNewPassword(userId, data)
        .then(res => {
          if (res.status === 200) {
            setLoading(false);
            fetchUser(userId.toString()).then(res => {
              if (res.status === 200) {
                dispatch(
                  set_user_reset_password(res.data.data.password_reset === 1 ? true : false),
                );
              }
            });
          }
        })
        .catch(err => {
          setServerErrorMessage(err?.response?.data?.error || 'An error occurred');
          setLoading(false);
        });
    }
  };

  const togglePasswordVisibility = (
    field: 'currentPassword' | 'newPassword' | 'confirmPassword',
  ) => {
    setPasswordVisibility(prev => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  return (
    <Dialog
      PaperProps={{ style: { minWidth: 590, borderRadius: 0, padding: 20, background: '#F8F8F8' } }}
      open={user_reset}
      keepMounted>
      <DialogContent>
        <Typography variant="h5">Reset Password</Typography>
        <DialogContentText>Please fill in the form below to reset your password.</DialogContentText>
        <Formik
          initialValues={initialValues}
          validationSchema={ResetPassword}
          onSubmit={data => {
            handleResetPassword(data);
          }}
          enableReinitialize>
          {({ values, isValid }) => (
            <Form>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Field name="current_password">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        label="Current password"
                        fullWidth
                        required
                        margin="normal"
                        value={values.current_password}
                        type={passwordVisibility.currentPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => togglePasswordVisibility('currentPassword')}
                                edge="end">
                                {passwordVisibility.currentPassword ? (
                                  <img
                                    alt="view"
                                    width="20"
                                    height="20"
                                    src="/images/icons/SVG/View.svg"
                                  />
                                ) : (
                                  <img
                                    alt="hide"
                                    width="20"
                                    height="20"
                                    src="/images/icons/SVG/Hide.svg"
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="current_password">
                    {message => <Typography color="error">{message}</Typography>}
                  </ErrorMessage>
                </Grid>

                <Grid item>
                  <Field name="password">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        label="Password"
                        required
                        margin="normal"
                        type={passwordVisibility.newPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => togglePasswordVisibility('newPassword')}
                                edge="end">
                                {passwordVisibility.newPassword ? (
                                  <img
                                    alt="view"
                                    width="20"
                                    height="20"
                                    src="/images/icons/SVG/View.svg"
                                  />
                                ) : (
                                  <img
                                    alt="hide"
                                    width="20"
                                    height="20"
                                    src="/images/icons/SVG/Hide.svg"
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={values.password}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="password">
                    {message => <Typography color="error">{message}</Typography>}
                  </ErrorMessage>
                </Grid>

                <Grid item>
                  <Field name="password_confirmation">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        label="Confirm New Password"
                        required
                        margin="normal"
                        type={passwordVisibility.confirmPassword ? 'text' : 'password'}
                        value={values.password_confirmation}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => togglePasswordVisibility('confirmPassword')}
                                edge="end">
                                {passwordVisibility.confirmPassword ? (
                                  <img
                                    alt="view"
                                    width="20"
                                    height="20"
                                    src="/images/icons/SVG/View.svg"
                                  />
                                ) : (
                                  <img
                                    alt="hide"
                                    width="20"
                                    height="20"
                                    src="/images/icons/SVG/Hide.svg"
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="password_confirmation">
                    {message => <Typography color="error">{message}</Typography>}
                  </ErrorMessage>
                </Grid>

                {serverErrorMessage && (
                  <Grid item>
                    <Typography color="error" textAlign="center">
                      {serverErrorMessage}
                    </Typography>
                  </Grid>
                )}

                <Grid item>
                  <LoadingButton
                    loading={loading}
                    loadingIndicator={<Watch color="#063e84" width={30} height={20} />}
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={!isValid}>
                    Reset
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ForceResetPassword;
