import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { set_user, set_user_reset_password } from '../Store/reducers/userReducer';
import api from '../services/api/axios-interceptor';
import ForceResetPassword from '../UserAuthentication/Components/ForceResetPassword/ForceResetPassword';

type AdminProtectedRouteType = {
  children: React.ReactElement;
};

const AdminProtectedRoute: React.FC<AdminProtectedRouteType> = ({
  children,
}: AdminProtectedRouteType) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.id) {
      api.get(`/users/${user.id}`).then(res => {
        dispatch(set_user_reset_password(res.data.data.password_reset === 1 ? true : false));
        dispatch(set_user(res.data.data));
        localStorage.setItem('user', JSON.stringify(res.data.data));
      });
    }
  }, [dispatch, navigate, user]);

  if (user?.id && user?.is_admin) {
    return (
      <>
        {children}
        <ForceResetPassword />
      </>
    );
  }

  return <Navigate to="/" />;
};

export default AdminProtectedRoute;
