import { createSlice, Slice } from '@reduxjs/toolkit';
import { DialogReducerTypes } from './types/dialogReducer.types';

const initialState: DialogReducerTypes = {
  archive_dialog: false,

  preview_dialog: false,

  error_toast_state: false,
  error_toast_message: '',

  success_toast_state: false,
  success_toast_message: '',
  success_toast_artwork_id: '',
  success_toast_internal: false,
};

export const dialogSlice: Slice<DialogReducerTypes> = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    set_archive_dialog: (state, action) => {
      state.archive_dialog = action.payload;
    },
    set_preview_dialog: (state, action) => {
      state.preview_dialog = action.payload;
    },
    set_error_toast: (state, action) => {
      state.error_toast_state = action.payload.state;
      state.error_toast_message = action.payload.message;
    },
    set_success_toast: (state, action) => {
      state.success_toast_state = action.payload.state;
      state.success_toast_message = action.payload.message;
      state.success_toast_artwork_id = action.payload.artwork_id;
      state.success_toast_internal = action.payload.internal;
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_archive_dialog, set_preview_dialog, set_error_toast, set_success_toast } =
  dialogSlice.actions;

export default dialogSlice.reducer;
