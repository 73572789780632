import { AxiosPromise } from 'axios';
import api from './axios-interceptor';
import { TableFilterObjectTypes } from '../../shared/types';

export const fetchUserList = (): AxiosPromise => {
  return api.get(`/users`);
};

export const fetchUserListFilter = ({
  search,
  sort,
  perPage,
  page = 1,
}: TableFilterObjectTypes): AxiosPromise => {
  return api.get(
    `/users${page && `?page=${page}`}${sort && `&sort=${sort}`}${perPage && `&perPage=${perPage}`}${
      search && `&filter[search]=${search}`
    }`,
  );
};

export const fetchUser = (id: string): AxiosPromise => {
  return api.get(`/users/${id}`);
};

export const checkExistingEmail = (email: string): AxiosPromise => {
  return api.get(`/users?email=${email}`);
};

export const createUser = (data: any): AxiosPromise => {
  return api.post(`/users`, data);
};

export const updateUserAction = (id: string, data: any): AxiosPromise => {
  return api.put(`/users/${id}`, data);
};

export const deleteUser = (id: string): AxiosPromise => {
  return api.delete(`/users/${id}`);
};

export const disableUser = (id: string, value: boolean): AxiosPromise => {
  return api.put(`/users/${id}`, { is_disabled: value });
};

export const resetAllUserPasswords = (): AxiosPromise => {
  return api.post(`/users/force-password-reset`);
};

export const setNewPassword = (id: number, data: any): AxiosPromise => {
  return api.post(`/users/${id}/password-reset`, data);
};
