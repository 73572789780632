import { createSlice, Slice } from '@reduxjs/toolkit';
import { ScreenerPageReducerTypes } from './types/screenerPageReducer.types';
import { ScreenerType } from '../../shared/types/Screener.types';

const initialState: ScreenerPageReducerTypes = {
  create_screener_drawer: false,
  screener_details_drawer: false,
  edit_screener_drawer: false,
  screener_details: null,
  delete_screener_dialog: false,
};

export const screenerPageSlice: Slice<ScreenerPageReducerTypes> = createSlice({
  name: 'screenerPage',
  initialState,
  reducers: {
    set_create_screener_drawer: (state, action) => {
      state.create_screener_drawer = action.payload;
    },
    set_screener_details_drawer: (state, action) => {
      state.screener_details_drawer = action.payload;
    },
    set_edit_screener_drawer: (state, action) => {
      state.edit_screener_drawer = action.payload;
    },
    set_screener: (state, action) => {
      state.screener_details = action.payload;
    },
    set_delete_screener_dialog: (state, action) => {
      state.delete_screener_dialog = action.payload;
    },
  },
});

export const {
  set_create_screener_drawer,
  set_screener_details_drawer,
  set_edit_screener_drawer,
  set_screener,
  set_delete_screener_dialog,
} = screenerPageSlice.actions;

export default screenerPageSlice.reducer;
