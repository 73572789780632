import { createSlice, Slice } from '@reduxjs/toolkit';
import { UserReducerTypes } from './types/userReducer.types';
import { boolean } from 'yup';

const initialState: UserReducerTypes = {
  user: null,
  loaded: true,
  users: {
    loading: false,
    data: [],
    error: '',
    meta: null,
  },
  user_details: null,
  user_reset_password: false,
};

export const userSlice: Slice<UserReducerTypes> = createSlice({
  name: 'user',
  initialState,
  reducers: {
    set_user: (state, action) => {
      state.user = action.payload;
    },
    set_users: (state, action) => {
      state.users = action.payload;
    },
    set_user_details: (state, action) => {
      state.user_details = action.payload;
    },
    set_user_reset_password: (state, action) => {
      state.user_reset_password = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_user, set_users, set_user_details, set_user_reset_password } = userSlice.actions;

export default userSlice.reducer;
